import { Route, Routes } from "react-router-dom";
import Homes from "./Homes";
import ViewHome from "./ViewHome";

const HomeRouter = ({ token }) => (
  <Routes>
    <Route path="/" element={<Homes />} />
    <Route path="/:home_id" element={<ViewHome />} />
  </Routes>
);

export default HomeRouter;
