import { createContext, useContext, useState, useEffect } from "react";
import axios from "../utils/axios";

// init context
const AuthContext = createContext();

// export the consumer
export function useAuth() {
  return useContext(AuthContext);
}

// export the provider (handle all the logic here)
export function AuthProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [account, setAccount] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("token") || null);
  const [refreshToken, setRefreshToken] = useState(
    localStorage.getItem("refreshToken") || null
  );

  const login = (formData = {}) =>
    new Promise((resolve, reject) => {
      axios
        .post("/auth/login", formData)
        .then((response) => {
          if (response && response.data) {
            const {
              data: accountData,
              token: accessToken,
              refreshToken: newRefreshToken,
            } = response.data;

            setAccount(accountData);
            setToken(accessToken);
            setIsAdmin(false);
            setRefreshToken(newRefreshToken);
            setIsLoggedIn(true);
            resolve(true);
          } else {
            console.error("No data found in the response");
            reject("No data found in the response");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error?.response?.data?.message || error.message);
        });
    });

  const adminLogin = (formData = {}) =>
    new Promise((resolve, reject) => {
      axios
        .post("/admin/login", formData)
        .then((response) => {
          if (response && response.data) {
            const {
              data: accountData,
              token: accessToken,
              refreshToken: newRefreshToken,
            } = response.data;

            setAccount(accountData);
            setToken(accessToken);
            setIsAdmin(true);
            setRefreshToken(newRefreshToken);
            setIsLoggedIn(true);
            resolve(true);
          } else {
            console.error("No data found in the response");
            reject("No data found in the response");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error?.response?.data?.message || error.message);
        });
    });

  const logout = () => {
    setIsLoggedIn(false);
    setAccount(null);
    setToken(null);
    setRefreshToken(null);
  };

  const refreshTokenHandler = async () => {
    try {
      const response = await axios.post("/auth/refresh-token", {
        refreshToken,
      });

      if (response && response.data) {
        const { token: newAccessToken, refreshToken: newRefreshToken } =
          response.data;

        setToken(newAccessToken);
        setRefreshToken(newRefreshToken);
      } else {
        console.error("No data found in the response");
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.status === 401) {
        setToken(null);
        setRefreshToken(null);
      }
    }
  };

  const adminRefreshTokenHandler = async () => {
    try {
      const response = await axios.post("/admin/refresh-token", {
        refreshToken,
      });

      if (response && response.data) {
        const { token: newAccessToken, refreshToken: newRefreshToken } =
          response.data;

        setToken(newAccessToken);
        setRefreshToken(newRefreshToken);
      } else {
        console.error("No data found in the response");
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.status === 401) {
        setToken(null);
        setRefreshToken(null);
      }
    }
  };

  const loginWithToken = async () => {
    try {
      const response = await axios.get("/auth/login", {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      if (response && response.data) {
        const { data: accountData, token: accessToken } = response.data;

        setAccount(accountData);
        setToken(accessToken);
        setIsAdmin(false);
        setIsLoggedIn(true);
      } else {
        console.error("No data found in the response");
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.status === 401) {
        await refreshTokenHandler();
      }
    }
  };

  const adminLoginWithToken = async () => {
    try {
      const response = await axios.get("/admin/login", {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      if (response && response.data) {
        const { data: accountData, token: accessToken } = response.data;

        setAccount(accountData);
        setToken(accessToken);
        setIsAdmin(true);
        setIsLoggedIn(true);
      } else {
        console.error("No data found in the response");
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.status === 401) {
        await adminRefreshTokenHandler();
      }
    }
  };

  const register = async (formData = {}) => {
    try {
      const response = await axios.post("/auth/register", formData);

      if (response && response.message) {
        console.log(response.message);
      } else {
        console.error("No data found in the response");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createAdmin = async (formData = {}) => {
    try {
      const response = await axios.post(
        "/auth/create-admin-user",
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
        formData
      );

      if (response && response.message) {
        console.log(response.message);
      } else {
        console.error("No data found in the response");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createComment = async (formData = {}) => {
    try {
      console.log("CREATING COMMENT: ", token, formData);
      const response = await axios.post(
        "/admin/comments/add-comment",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response && response.message) {
        console.log(response.message);
      } else {
        console.error("No data found in the response");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // This side effect keeps local storage updated with recent token value,
  // making sure it can be re-used upon refresh or re-open browser
  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
    } else {
      localStorage.removeItem("token");
    }

    if (refreshToken) {
      localStorage.setItem("refreshToken", refreshToken);
    } else {
      localStorage.removeItem("refreshToken");
    }
  }, [token, refreshToken]);

  // This side effect runs only if we have a token, but no account or logged-in boolean.
  // This "if" statement is "true" only when refreshed, or re-opened the browser,
  // if true, it will then ask the backend for the account information (and will get them if the token hasn't expired)
  useEffect(() => {
    if (!isLoggedIn && !account && token) adminLoginWithToken();
  }, [isLoggedIn, account, token]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        account,
        token,
        refreshToken,
        isAdmin,
        createComment,
        login,
        register,
        logout,
        refreshTokenHandler,
        adminLogin,
        adminLoginWithToken,
        adminRefreshTokenHandler,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
