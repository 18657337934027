import { useAuth } from "../../contexts/AuthContext";
import React from "react";

export default function Dashboard() {
  const { account, token } = useAuth();

  const navStyle = {
    display: "flex",
    justifyContent: "space-around",
    listStyle: "none",
    padding: "10px",
    backgroundColor: "#f3f3f3",
    borderBottom: "1px solid #ddd",
  };

  const linkStyle = {
    textDecoration: "none",
    color: "black",
    fontSize: "18px",
  };

  return (
    <div>
      <p>
        Hey, {account.email}! I'm happy to let you know: you are authenticated!
      </p>
    </div>
  );
}
