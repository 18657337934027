import TemplatePane from "./TemplatePane";
import { useApi } from "../../contexts/ApiContext";

const FILTER_FIELDS = [
  { name: "_id", label: "Filter by home ID" },
  { name: "home_name", label: "Filter by home name" },
  { name: "home_address", label: "Filter by address" },
  { name: "home_city", label: "Filter by city" },
  { name: "home_state", label: "Filter by state" },
  { name: "home_country", label: "Filter by country" },
  { name: "home_zip", label: "Filter by zip" },
  { name: "primary_owner", label: "Filter by primary owner" },
  { name: "createdAt", label: "Filter by created at" },
  { name: "updatedAt", label: "Filter by updated at" },
];

const TABLE_FIELDS = [
  { name: "_id", label: "Home ID" },
  { name: "home_name", label: "Home Name" },
  { name: "home_address", label: "Street" },
  { name: "home_city", label: "City" },
  { name: "home_state", label: "State" },
  { name: "home_country", label: "Country" },
  { name: "home_zip", label: "Zip" },
  { name: "primary_owner", label: "Primary Owner (User ID)" },
  { name: "createdAt", label: "Created At" },
  { name: "updatedAt", label: "Updated At" },
];

export default function HomesPane() {
  const { getHomes } = useApi();

  return (
    <TemplatePane
      getItems={getHomes}
      filterFields={FILTER_FIELDS}
      tableFields={TABLE_FIELDS}
    />
  );
}
