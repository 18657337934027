import { useAuth } from "./contexts/AuthContext";
import AuthNav from "./navigation/AuthNav";
import AppNav from "./navigation/AppNav";
import EnvironmentBanner from "./components/EnvironmentBanner";

export default function App() {
  const { isLoggedIn } = useAuth();

  return (
    <div className="App">
      <EnvironmentBanner />
      {isLoggedIn ? <AppNav /> : <AuthNav />}
    </div>
  );
}
