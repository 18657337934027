import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button,
  TextField,
} from "@mui/material";
import { styled } from "@mui/system";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
}));

export default function DetailsPaneTemplate({
  data,
  isEditing,
  handleInputChange,
  handleSubmit,
}) {
  return (
    <form noValidate onSubmit={handleSubmit}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={row.label}>
                <StyledTableCell>{row.label}</StyledTableCell>
                <TableCell>
                  {isEditing && row.editable ? (
                    <TextField
                      value={row.value || ""}
                      onChange={(e) =>
                        handleInputChange(row.label, e.target.value)
                      }
                    />
                  ) : row.label === "profile_image_url" ? (
                    <a target="_blank" href={row.value}>
                      {row.value}
                    </a>
                  ) : (
                    row.value
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {isEditing && <Button type="submit">Save Changes</Button>}
      </TableContainer>
    </form>
  );
}
