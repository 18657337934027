import { Button } from "@mui/material";
import { useApi } from "../contexts/ApiContext";

export default function DeleteCommentButton({ id, type = 0, index = 0 }) {
  const { deleteComment } = useApi();

  const clickSubmit = async () => {
    try {
      await deleteComment({ id, type, index });
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Button color="primary" variant="contained" onClick={clickSubmit}>
      Delete Comment
    </Button>
  );
}
