import { NODE_ENV_ENUM } from "../constants";
import { useState, useEffect } from "react";

export default function EnvironmentBanner() {
  const [environment, setEnvironment] = useState(NODE_ENV_ENUM.PRODUCTION);

  useEffect(() => {
    if (process.env.REACT_APP_NODE_ENV === NODE_ENV_ENUM.PRODUCTION) {
      setEnvironment(NODE_ENV_ENUM.PRODUCTION);
    } else if (process.env.REACT_APP_NODE_ENV === NODE_ENV_ENUM.DEVELOPMENT) {
      setEnvironment(NODE_ENV_ENUM.DEVELOPMENT);
    } else if (process.env.REACT_APP_NODE_ENV === NODE_ENV_ENUM.STAGING) {
      setEnvironment(NODE_ENV_ENUM.STAGING);
    } else {
      setEnvironment(NODE_ENV_ENUM.DEVELOPMENT);
    }
  }, []);

  const bannerStyle = {
    position: "fixed",
    top: "25px",
    right: "-50px",
    width: "200px",
    height: "50px",
    lineHeight: "50px",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "white",
    textAlign: "center",
    transform: "rotate(45deg)",
    zIndex: 1000,
  };

  return (
    <div style={bannerStyle}>
      <span>{environment !== NODE_ENV_ENUM.PRODUCTION && environment}</span>
    </div>
  );
}
