import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
} from "@mui/material";
import { Link } from "react-router-dom";
import CreateCommentButton from "../CreateCommentButton";
import DeleteCommentButton from "../DeleteCommentButton";

export default function CommentPane({ comments, id, type }) {
  return (
    <div>
      <h2>
        Comments
        <CreateCommentButton id={id} type={type}>
          Add comment
        </CreateCommentButton>
      </h2>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Comment</TableCell>
              <TableCell>Admin User ID</TableCell>
              <TableCell>Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {comments &&
              comments.map((comment, index) => (
                <TableRow key={comment.createdAt}>
                  <TableCell>{comment.text}</TableCell>
                  <TableCell>
                    <Link to={`/admin/admin-users/${comment.admin_user_id}`}>
                      {comment.admin_user_id}
                    </Link>
                  </TableCell>
                  <TableCell>{comment.createdAt}</TableCell>
                  <TableCell>
                    <DeleteCommentButton id={id} type={type} index={index} />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
