import { useState, useEffect } from "react";
import DetailsPaneTemplate from "./DetailsPaneTemplate";
import { useApi } from "../../contexts/ApiContext";

export default function UserDetailsPane({
  user,
  isEditing,
  setEditing,
  setUser,
  isAdmin,
}) {
  const { updateUser } = useApi();
  const editableFields = isAdmin
    ? ["first_name", "last_name", "email"]
    : ["first_name", "last_name", "email", "phone", "country_code"];
  const [tempUser, setTempUser] = useState(user);

  useEffect(() => {
    setTempUser(user);
  }, [user]);

  const handleInputChange = (fieldKey, newValue) => {
    setTempUser({ ...tempUser, [fieldKey]: newValue });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updates = editableFields.reduce(
      (obj, key) => ({ ...obj, [key]: tempUser[key] }),
      {}
    );
    updateUser({ user_id: user._id, isAdmin, updates }).then((res) => {
      if (res) {
        setUser(res);
        setEditing(false);
      } else {
        console.log("Error updating user");
        setEditing(false);
      }
    });
  };

  return (
    <DetailsPaneTemplate
      isEditing={isEditing}
      handleInputChange={handleInputChange}
      handleSubmit={handleSubmit}
      data={Object.keys(user).map((key) => ({
        label: key,
        value: tempUser[key],
        editable: editableFields.includes(key),
      }))}
    />
  );
}
