import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import { Link } from "react-router-dom";
import { ContentCopy } from "@mui/icons-material";

export default function AuditsPane({ audits }) {
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <div>
      <h2>Audits</h2>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Audit ID</TableCell>
              <TableCell>Audit Type</TableCell>
              <TableCell>Audit User</TableCell>
              <TableCell>User Type</TableCell>
              <TableCell>Auditable ID (Target)</TableCell>
              <TableCell>Action</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>
                Changes
                <br />
                <a
                  href="https://jsonformatter.org"
                  target="_blank"
                  rel="noreferrer"
                >
                  JSON Viewer
                </a>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {audits &&
              audits.map((audit) => (
                <TableRow key={audit._id}>
                  <TableCell>{audit._id}</TableCell>
                  <TableCell>{audit.audit_type}</TableCell>
                  <TableCell>
                    <Link
                      to={`/admin/${
                        audit.userType === 0 ? "users" : "admin-users"
                      }/${audit.user}`}
                    >
                      {audit.user}
                    </Link>
                  </TableCell>
                  <TableCell>{audit.userType}</TableCell>
                  <TableCell>{audit.target}</TableCell>
                  <TableCell>{audit.action}</TableCell>
                  <TableCell>{audit.createdAt}</TableCell>
                  <TableCell>
                    {audit.changes && (
                      <Box
                        component="div"
                        sx={{
                          position: "relative",
                          backgroundColor: "#f5f5f5",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <IconButton
                          sx={{
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                          }}
                          size="small"
                          onClick={() =>
                            copyToClipboard(
                              JSON.stringify(audit.changes, null, 2)
                            )
                          }
                        >
                          <ContentCopy />
                        </IconButton>
                        <Typography component="div">
                          <pre>{JSON.stringify(audit.changes, null, 2)}</pre>
                        </Typography>
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
