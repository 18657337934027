import { createContext, useContext, useState } from "react";
import axios from "../utils/axios";

// init context
const ApiContext = createContext();

export function useApi() {
  return useContext(ApiContext);
}

// export the provider (handle all the logic here)
export function ApiProvider({ children }) {
  const [token] = useState(localStorage.getItem("token") || null);

  const getAudits = async (formData = {}) => {
    try {
      const response = await axios
        .get("/admin/audits/" + formData.target, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          return res;
        });
      return response.data.audits;
    } catch (error) {
      console.error(error);
    }
  };

  const getHomes = async (formData = {}) => {
    try {
      const response = await axios
        .get("/admin/homes/", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          return res;
        });
      return response.data.homes;
    } catch (error) {
      console.error(error);
    }
  };

  const getHomeByID = async ({ home_id = "" }) => {
    try {
      const response = await axios
        .get(`/admin/homes/${home_id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          return res;
        });
      return response.data.home;
    } catch (error) {
      console.error(error);
    }
  };

  const getUserByID = async ({ user_id = "", isAdmin = false }) => {
    try {
      const response = await axios
        .get(`/admin/${isAdmin ? "admin-" : ""}users/${user_id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          return res;
        });
      return response.data.user;
    } catch (error) {
      console.error(error);
    }
  };

  const updateHome = async ({ home_id = "", updates = {} }) => {
    try {
      const response = await axios
        .put(`/admin/homes/${home_id}`, updates, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          return res;
        });
      return response.data.home;
    } catch (error) {
      console.error(error);
    }
  };

  const deleteHome = async ({ home_id = "" }) => {
    try {
      const response = await axios
        .delete(`/admin/homes/${home_id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          return res;
        });
      return response.data.message;
    } catch (error) {
      console.error(error);
    }
  };

  const deleteUser = async ({ user_id = "" }) => {
    try {
      const response = await axios
        .delete(`/admin/users/${user_id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          return res;
        });
      return response.data.message;
    } catch (error) {
      console.error(error);
    }
  };

  const deleteAdminUser = async ({ user_id = "" }) => {
    try {
      const response = await axios
        .delete(`/admin/admin-users/${user_id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          return res;
        });
      return response.data.message;
    } catch (error) {
      console.error(error);
    }
  };

  const updateUser = async ({
    user_id = "",
    updates = {},
    isAdmin = false,
  }) => {
    try {
      const response = await axios
        .post(`/admin/${isAdmin ? "admin-" : ""}users/${user_id}`, updates, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          return res;
        });
      return response.data.user;
    } catch (error) {
      console.error(error);
    }
  };

  const getAdminUsers = async (formData = {}) => {
    try {
      const response = await axios
        .get("/admin/admin-users/", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          return res;
        });
      return response.data.users;
    } catch (error) {
      console.error(error);
    }
  };

  const getUsers = async (formData = {}) => {
    try {
      const response = await axios
        .get("/admin/users/", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          return res;
        });
      return response.data.users;
    } catch (error) {
      console.error(error);
    }
  };

  const createComment = async ({ type = 0, id, formData = {} }) => {
    try {
      const response = await axios.post(
        `/admin/comments/add-comment`,
        { text: formData.text, id, type },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response && response.message) {
        console.log(response.message);
      } else {
        console.error("No data found in the response");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteComment = async ({ type = 0, id, index = 0 }) => {
    try {
      const response = await axios.post(
        `/admin/comments/delete-comment`,
        { index, id, type },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response && response.message) {
        console.log(response.message);
      } else {
        console.error("No data found in the response");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ApiContext.Provider
      value={{
        createComment,
        getAudits,
        getHomes,
        getAdminUsers,
        getUsers,
        getHomeByID,
        updateHome,
        getUserByID,
        updateUser,
        deleteHome,
        deleteComment,
        deleteUser,
        deleteAdminUser,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
}
