import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Navigate,
  Routes,
} from "react-router-dom";
import Dashboard from "../screens/Admin/Dashboard";
import Header from "../components/Header";
import Footer from "../components/Footer";
import AdminUsersRouter from "../screens/Admin/AdminUsers";
import UsersRouter from "../screens/Admin/Users";
import HomesRouter from "../screens/Admin/Homes";
import { useAuth } from "../contexts/AuthContext";

const AdminRouter = ({ token }) => (
  <>
    <Header />
    <Routes>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route
        path="/admin-users/*"
        element={<AdminUsersRouter token={token} />}
      />
      <Route path="/users/*" element={<UsersRouter token={token} />} />
      <Route path="/homes/*" element={<HomesRouter token={token} />} />
      <Route path="/" element={<Navigate to="/admin/dashboard" />} />
      <Route path="*" element={<Navigate to="/admin/dashboard" />} />
    </Routes>
    <Footer />
  </>
);

const AppNav = () => {
  const { token, isAdmin } = useAuth();
  return (
    <div style={{ width: "100%" }}>
      {isAdmin ? (
        <Router>
          <Routes>
            <Route path="/admin/*" element={<AdminRouter token={token} />} />
            <Route path="*" element={<p>404 Error</p>} />
          </Routes>
        </Router>
      ) : (
        <p>Please use the mobile app.</p>
      )}
    </div>
  );
};

export default AppNav;
