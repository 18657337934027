import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  TableSortLabel,
} from "@mui/material";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

export default function TemplatePane({ getItems, filterFields, tableFields }) {
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [error, setError] = useState(null);
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const location = useLocation();

  const initialFilters = Object.fromEntries(
    filterFields.map((field) => [field.name, ""])
  );

  const [filters, setFilters] = useState(initialFilters);

  useEffect(() => {
    getItems().then((res) => {
      if (res) {
        setItems(res);
        setFilteredItems(res);
      } else {
        setError("No items found in the response");
      }
    });
  }, []);

  useEffect(() => {
    let filtered = items.filter((item) =>
      Object.entries(filters).every(([key, value]) =>
        item[key] && value
          ? item[key]
              .toString()
              .toLowerCase()
              .includes(value.toString().toLowerCase())
          : true
      )
    );

    if (sortField) {
      const sortMultiplier = sortDirection === "asc" ? 1 : -1;

      filtered.sort((a, b) => {
        if (a[sortField] < b[sortField]) {
          return -1 * sortMultiplier;
        }
        if (a[sortField] > b[sortField]) {
          return 1 * sortMultiplier;
        }
        return 0;
      });
    }

    setFilteredItems(filtered);
  }, [filters, items, sortField, sortDirection]);

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const clearFilters = () => {
    setFilters(initialFilters);
  };

  const handleSort = (field) => {
    const newSortDirection =
      sortField === field && sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(newSortDirection);
    setSortField(field);
  };

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ marginRight: "5px", maxWidth: "15%" }}>
          {filterFields.map((field) => (
            <TextField
              key={field.name}
              name={field.name}
              value={filters[field.name]}
              onChange={handleFilterChange}
              label={field.label}
            />
          ))}
          <Button onClick={clearFilters}>Clear Filters</Button>
        </div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {tableFields.map((field) => (
                  <TableCell key={field.name}>
                    <TableSortLabel
                      active={sortField === field.name}
                      direction={sortDirection}
                      onClick={() => handleSort(field.name)}
                    >
                      {field.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell key="Link">View</TableCell>
              </TableRow>
            </TableHead>
            {error ? (
              <div className="error">
                <p>Error: {error.toString()}</p>
              </div>
            ) : (
              <TableBody>
                {filteredItems.length > 0 ? (
                  filteredItems.map((item) => (
                    <TableRow key={item._id}>
                      {tableFields.map((field) => (
                        <TableCell key={field.name}>
                          {item[field.name]}
                        </TableCell>
                      ))}
                      <TableCell>
                        <Button
                          component={Link}
                          to={`${location.pathname}/${item._id}`}
                          variant="contained"
                          color="primary"
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell>No items found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
