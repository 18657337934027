import TemplatePane from "./TemplatePane";
import { useApi } from "../../contexts/ApiContext";

const USER_FILTER_FIELDS = [
  { name: "_id", label: "Filter by user ID" },
  { name: "email", label: "Filter by email" },
  { name: "first_name", label: "Filter by first name" },
  { name: "last_name", label: "Filter by last name" },
  { name: "phone", label: "Filter by phone" },
  { name: "country_code", label: "Filter by country code" },
  { name: "user_status", label: "Filter by status" },
  { name: "createdAt", label: "Filter by created at" },
  { name: "updatedAt", label: "Filter by updated at" },
];

const USER_TABLE_FIELDS = [
  { name: "_id", label: "User ID" },
  { name: "email", label: "Email" },
  { name: "first_name", label: "First Name" },
  { name: "last_name", label: "Last Name" },
  { name: "phone", label: "Phone" },
  { name: "country_code", label: "Country Code" },
  { name: "user_status", label: "Status" },
  { name: "createdAt", label: "Created At" },
  { name: "updatedAt", label: "Updated At" },
];

const ADMIN_FILTER_FIELDS = [
  { name: "_id", label: "Filter by user ID" },
  { name: "email", label: "Filter by email" },
  { name: "first_name", label: "Filter by first name" },
  { name: "last_name", label: "Filter by last name" },
  { name: "role", label: "Filter by role" },
  { name: "user_status", label: "Filter by status" },
  { name: "createdAt", label: "Filter by created at" },
  { name: "updatedAt", label: "Filter by updated at" },
];

const ADMIN_TABLE_FIELDS = [
  { name: "_id", label: "User ID" },
  { name: "email", label: "Email" },
  { name: "first_name", label: "First Name" },
  { name: "last_name", label: "Last Name" },
  { name: "role", label: "Role" },
  { name: "user_status", label: "Status" },
  { name: "createdAt", label: "Created At" },
  { name: "updatedAt", label: "Updated At" },
];

export default function UsersPane({ user_type = "user" }) {
  const { getUsers, getAdminUsers } = useApi();

  return (
    <TemplatePane
      getItems={user_type === "user" ? getUsers : getAdminUsers}
      filterFields={
        user_type === "user" ? USER_FILTER_FIELDS : ADMIN_FILTER_FIELDS
      }
      tableFields={
        user_type === "user" ? USER_TABLE_FIELDS : ADMIN_TABLE_FIELDS
      }
    />
  );
}
