import { Box, Typography, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: "#000",
    color: "#fff",
    position: "fixed",
    bottom: 0,
    padding: "5px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  link: {
    color: "#fff",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <Box className={classes.footer}>
      <Typography variant="h6">Secured AI Admin</Typography>
      <Box display="flex">
        <Link to="/about" className={classes.link}>
          About
        </Link>
        <Link to="/contact" className={classes.link}>
          Contact
        </Link>
        <Link to="/privacy-policy" className={classes.link}>
          Privacy Policy
        </Link>
        <Link to="/terms-of-service" className={classes.link}>
          Terms of Service
        </Link>
      </Box>
    </Box>
  );
}
