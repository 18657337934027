import { Route, Routes } from "react-router-dom";
import Users from "./Users";
import ViewUser from "../../../components/ViewUser";

const UsersRouter = () => (
  <Routes>
    <Route path="/" element={<Users />} />
    <Route path="/:user_id" element={<ViewUser />} />
  </Routes>
);

export default UsersRouter;
