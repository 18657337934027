import { useState, useEffect } from "react";
import DetailsPaneTemplate from "./DetailsPaneTemplate";
import { useApi } from "../../contexts/ApiContext";

export default function HomeDetailsPane({
  home,
  isEditing,
  setEditing,
  setHome,
}) {
  const { updateHome } = useApi();
  const editableFields = [
    "home_name",
    "home_address",
    "home_city",
    "home_state",
    "home_zip",
    "primary_owner",
  ];
  const [tempHome, setTempHome] = useState(home);

  useEffect(() => {
    setTempHome(home);
  }, [home]);

  const handleInputChange = (fieldKey, newValue) => {
    setTempHome({ ...tempHome, [fieldKey]: newValue });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updates = editableFields.reduce(
      (obj, key) => ({ ...obj, [key]: tempHome[key] }),
      {}
    );
    updateHome({ home_id: home._id, updates }).then((res) => {
      if (res) {
        setHome(res);
        setEditing(false);
      } else {
        console.log("Error updating home");
        setEditing(false);
      }
    });
  };

  return (
    <DetailsPaneTemplate
      isEditing={isEditing}
      handleInputChange={handleInputChange}
      handleSubmit={handleSubmit}
      data={Object.keys(home).map((key) => ({
        label: key,
        value: tempHome[key],
        editable: editableFields.includes(key),
      }))}
    />
  );
}
