import { Fragment, useState } from "react";
import {
  AppBar,
  IconButton,
  Avatar,
  Popover,
  List,
  ListSubheader,
  ListItemButton,
  Box,
  Button,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import OnlineIndicator from "./OnlineIndicator";
import AuthModal from "./AuthModal";
import { useAuth } from "../contexts/AuthContext";

export default function Header() {
  const { isLoggedIn, account, logout } = useAuth();

  const [anchorEl, setAnchorEl] = useState(null);
  const [popover, setPopover] = useState(false);
  const [authModal, setAuthModal] = useState(false);

  const openPopover = (e) => {
    setPopover(true);
    setAnchorEl(e.currentTarget);
  };

  const closePopover = () => {
    setPopover(false);
    setAnchorEl(null);
  };

  const clickLogin = () => {
    setAuthModal(true);
    closePopover();
  };

  return (
    <AppBar className="header" position="static">
      <Box display="flex" justifyContent="flex" alignItems="center" p={2}>
        <h1>Secured AI Admin</h1>
        <Box flexGrow={1} display="flex-end" justifyContent="flex-end">
          <Button
            component={RouterLink}
            className="header-links"
            to="/admin/dashboard"
          >
            Dashboard
          </Button>
          <Button
            component={RouterLink}
            className="header-links"
            to="/admin/users"
          >
            Users
          </Button>
          <Button
            component={RouterLink}
            className="header-links"
            to="/admin/admin-users"
          >
            Admin Users
          </Button>
          <Button
            component={RouterLink}
            className="header-links"
            to="/admin/homes"
          >
            Homes
          </Button>
        </Box>

        <IconButton onClick={openPopover}>
          <OnlineIndicator online={isLoggedIn}>
            <Avatar src={account?.email || ""} alt={account?.email || ""} />
          </OnlineIndicator>
        </IconButton>
      </Box>

      <Popover
        anchorEl={anchorEl}
        open={popover}
        onClose={closePopover}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <List style={{ minWidth: "100px" }}>
          <ListSubheader style={{ textAlign: "center" }}>
            Hello, {isLoggedIn ? account.email : "Guest"}
          </ListSubheader>

          {isLoggedIn ? (
            <ListItemButton onClick={logout}>Logout</ListItemButton>
          ) : (
            <Fragment>
              <ListItemButton onClick={clickLogin}>Login</ListItemButton>
              <ListItemButton onClick={clickLogin}>
                Forgot Password
              </ListItemButton>
            </Fragment>
          )}
        </List>
      </Popover>

      <AuthModal open={authModal} close={() => setAuthModal(false)} />
    </AppBar>
  );
}
