import HomesPane from "../../../components/Panes/HomesPane";

export default function Homes() {
  return (
    <div>
      <h1>Homes</h1>
      <HomesPane />
    </div>
  );
}
