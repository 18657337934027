import { Fragment, useState } from "react";
import {
  Dialog,
  DialogTitle,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import { useApi } from "../contexts/ApiContext";

const textFieldSx = { mx: 2, my: 0.5 };

export default function CreateCommentButton({ id, type = "user" }) {
  const [open, setOpen] = useState(false);
  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);
  return (
    <div>
      <Button onClick={() => openModal()}>Create Comment</Button>
      <CreateCommentModalModal
        type={type}
        id={id}
        open={open}
        close={closeModal}
      />
    </div>
  );
}

function CreateCommentModalModal({ open, close, id, type = "user" }) {
  const { createComment } = useApi();

  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { value } = e.target;
    setFormData((prev) => ({
      ...prev,
      text: value,
    }));
  };

  const clickSubmit = async () => {
    setLoading(true);
    setError("");
    try {
      await createComment({ id, type, formData });
      close();
    } catch (error) {
      setError(error);
    }

    setLoading(false);
  };

  const disabledButton = !formData["text"];

  return (
    <Dialog open={open} onClose={close}>
      <UserForm formData={formData} handleChange={handleChange} />

      {error && <span className="error">{error}</span>}

      {loading ? (
        <center>
          <CircularProgress color="inherit" />
        </center>
      ) : (
        <>
          <Button onClick={clickSubmit} disabled={disabledButton}>
            Create Comment
          </Button>
          <Button onClick={() => close()}>Close</Button>
        </>
      )}
    </Dialog>
  );
}

function UserForm({ formData, handleChange }) {
  return (
    <Fragment>
      <DialogTitle>Create a new comment</DialogTitle>
      <TextField
        label="Comment"
        name="text"
        type="text"
        value={formData["text"] || ""}
        onChange={handleChange}
        variant="filled"
        sx={textFieldSx}
      />
    </Fragment>
  );
}
