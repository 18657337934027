import CreateAdminUserButton from "../../../components/CreateAdminUserButton";
import UsersPane from "../../../components/Panes/UsersPane";

export default function AdminUsers() {
  return (
    <div>
      <h1>Admin Users</h1>
      <CreateAdminUserButton />
      <UsersPane user_type="admin" />
    </div>
  );
}
