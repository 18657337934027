import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useParams } from "react-router-dom";

import { Box, Container, Typography, Button, Avatar } from "@mui/material";
import { useApi } from "../contexts/ApiContext";
import UserDetailsPane from "./Panes/UserDetailsPane";
import AuditsPane from "./Panes/AuditsPane";
import CommentsPane from "./Panes/CommentsPane";
import DeleteButton from "./DeleteButton";

export default function ViewUser({ isAdmin = false }) {
  const { user_id } = useParams();
  const { getUserByID, getAudits, deleteAdminUser, deleteUser } = useApi();
  const [user, setUser] = useState({});
  const [audits, setAudits] = useState([]);
  const [error, setError] = useState(null);

  const [isEditing, setEditing] = useState(false);
  const toggleEditing = () => setEditing(!isEditing);

  useEffect(() => {
    getUserByID({ user_id, isAdmin }).then((res) => {
      if (res) {
        setUser(res);
      } else {
        setError("No user found in the response");
      }
    });

    getAudits({ target: user_id }).then((res) => {
      if (res) {
        setAudits(res);
      } else {
        setError("No audits found in the response");
      }
    });
  }, []);

  const handleDelete = async () => {
    try {
      const response = isAdmin
        ? await deleteAdminUser({ user_id })
        : await deleteUser({ user_id });
      if (response) {
        console.log(response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      {error ? (
        <div className="error">
          <p>Error: {error.toString()}</p>
        </div>
      ) : (
        <Container maxWidth="lg">
          <Box my={4} display="flex" alignItems="center">
            {!isAdmin && (
              <Avatar
                alt={user?.first_name + " " + user?.last_name}
                src={user?.profile_image_url}
                sx={{ width: 56, height: 56, marginRight: 2 }}
              />
            )}
            <div>
              <Typography variant="h4" component="h2">
                {user?.first_name + " " + user?.last_name + " - " + user?.email}
              </Typography>
              <Typography variant="h6" component="h3">
                {user?._id}
              </Typography>
              <Button variant="outlined" onClick={toggleEditing}>
                {isEditing ? "Cancel" : "Edit"}
              </Button>
              <DeleteButton
                redirectPath={isAdmin ? "/admin/admin-users" : "/admin/users"}
                deleteApi={handleDelete}
              />
            </div>
          </Box>
          <UserDetailsPane
            user={_.omit(user, ["comments"])}
            setUser={setUser}
            isEditing={isEditing}
            setEditing={setEditing}
            isAdmin={isAdmin}
          />
          <CommentsPane
            id={user_id}
            type={isAdmin ? 1 : 0}
            comments={user?.comments}
          />
          <AuditsPane audits={audits} />
        </Container>
      )}
    </div>
  );
}
