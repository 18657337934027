import { Fragment, useState } from "react";
import {
  Dialog,
  DialogTitle,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import { useAuth } from "../contexts/AuthContext";

const textFieldSx = { mx: 2, my: 0.5 };

export default function AuthModal({
  open,
  close,
  isRegisterMode,
  toggleRegister,
}) {
  const { login, register } = useAuth();

  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const clickSubmit = async () => {
    setLoading(true);
    setError("");

    try {
      isRegisterMode ? await register(formData) : await login(formData);
      close();
    } catch (error) {
      setError(error);
    }

    setLoading(false);
  };

  const disabledLoginButton = !formData["email"] || !formData["password"];
  const disabledRegisterButton = !formData["email"] || !formData["password"];

  return (
    <Dialog open={open} onClose={close}>
      <LoginForm formData={formData} handleChange={handleChange} />

      {error && <span className="error">{error}</span>}

      {loading ? (
        <center>
          <CircularProgress color="inherit" />
        </center>
      ) : (
        <Button
          onClick={clickSubmit}
          disabled={
            isRegisterMode ? disabledRegisterButton : disabledLoginButton
          }
        >
          {isRegisterMode ? "Register" : "Login"}
        </Button>
      )}
    </Dialog>
  );
}

function LoginForm({ formData, handleChange }) {
  return (
    <Fragment>
      <DialogTitle>Login to your account</DialogTitle>

      <TextField
        label="email"
        name="email"
        type="text"
        value={formData["email"] || ""}
        onChange={handleChange}
        variant="filled"
        sx={textFieldSx}
        required
      />
      <TextField
        label="Password"
        name="password"
        type="password"
        value={formData["password"] || ""}
        onChange={handleChange}
        variant="filled"
        sx={textFieldSx}
        required
      />
    </Fragment>
  );
}
