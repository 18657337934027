import CreateUserButton from "../../../components/CreateUserButton";
import UsersPane from "../../../components/Panes/UsersPane";

export default function Users() {
  return (
    <div>
      <h1>Users</h1>
      <CreateUserButton />
      <UsersPane />
    </div>
  );
}
