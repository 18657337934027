import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import { Box, Container, Typography, Button } from "@mui/material";
import { useApi } from "../../../contexts/ApiContext";
import HomeDetailsPane from "../../../components/Panes/HomeDetailsPane";
import AuditsPane from "../../../components/Panes/AuditsPane";
import CommentsPane from "../../../components/Panes/CommentsPane";
import DeleteButton from "../../../components/DeleteButton";

export default function ViewHome() {
  const { home_id } = useParams();
  const { getHomeByID, getAudits, deleteHome } = useApi();
  const [home, setHome] = useState({});
  const [audits, setAudits] = useState([]);
  const [error, setError] = useState(null);

  const [isEditing, setEditing] = useState(false);
  const toggleEditing = () => setEditing(!isEditing);

  useEffect(() => {
    getHomeByID({ home_id }).then((res) => {
      if (res) {
        setHome(res);
      } else {
        setError("No homes found in the response");
      }
    });

    getAudits({ target: home_id }).then((res) => {
      if (res) {
        setAudits(res);
      } else {
        setError("No audits found in the response");
      }
    });
  }, [home_id]);

  const handleDelete = async () => {
    try {
      const response = await deleteHome({ home_id });
      if (response) {
        console.log(response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      {error ? (
        <div className="error">
          <p>Error: {error.toString()}</p>
        </div>
      ) : (
        <Container maxWidth="lg">
          <Box my={4} display="flex" alignItems="center">
            <div>
              <Typography variant="h4" component="h2">
                {home?.home_name}
              </Typography>
              <Typography variant="h6" component="h3">
                {home?._id}
              </Typography>
              <Button variant="outlined" onClick={toggleEditing}>
                {isEditing ? "Cancel" : "Edit"}
              </Button>
              <DeleteButton
                redirectPath="/admin/homes"
                deleteApi={handleDelete}
              />
            </div>
          </Box>
          <HomeDetailsPane
            home={_.omit(home, ["comments"])}
            setHome={setHome}
            isEditing={isEditing}
            setEditing={setEditing}
          />
          <CommentsPane id={home_id} type={2} comments={home?.comments} />
          <AuditsPane audits={audits} />
        </Container>
      )}
    </div>
  );
}
