import { Fragment, useState } from "react";
import {
  Dialog,
  DialogTitle,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import { useAuth } from "../contexts/AuthContext";

const textFieldSx = { mx: 2, my: 0.5 };

export default function CreateAdminUserButton() {
  const [open, setOpen] = useState(false);
  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);
  return (
    <div>
      <Button onClick={() => openModal()}>Create Admin User</Button>
      <CreateAdminUserModal open={open} close={closeModal} />
    </div>
  );
}

function CreateAdminUserModal({ open, close }) {
  const { createAdmin } = useAuth();

  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { first_name, last_name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [first_name]: value,
      [last_name]: value,
    }));
  };

  const clickSubmit = async () => {
    setLoading(true);
    setError("");

    try {
      await createAdmin(formData);
      close();
    } catch (error) {
      setError(error);
    }

    setLoading(false);
  };

  const disabledButton = !formData["email"] || !formData["password"];

  return (
    <Dialog open={open} onClose={close}>
      <UserForm formData={formData} handleChange={handleChange} />

      {error && <span className="error">{error}</span>}

      {loading ? (
        <center>
          <CircularProgress color="inherit" />
        </center>
      ) : (
        <>
          <Button onClick={clickSubmit} disabled={disabledButton}>
            Create User
          </Button>
          <Button onClick={() => close()}>Close</Button>
        </>
      )}
    </Dialog>
  );
}

function UserForm({ formData, handleChange }) {
  return (
    <Fragment>
      <DialogTitle>Create a new user</DialogTitle>
      <TextField
        label="Email"
        name="email"
        type="email"
        value={formData["email"] || ""}
        onChange={handleChange}
        variant="filled"
        sx={textFieldSx}
        required
      />
      <TextField
        label="Password"
        name="password"
        type="password"
        value={formData["password"] || ""}
        onChange={handleChange}
        variant="filled"
        sx={textFieldSx}
        required
      />
      <TextField
        label="First Name"
        name="first_name"
        type="text"
        value={formData["first_name"] || ""}
        onChange={handleChange}
        variant="filled"
        sx={textFieldSx}
      />
      <TextField
        label="Last Name"
        name="last_name"
        type="text"
        value={formData["last_name"] || ""}
        onChange={handleChange}
        variant="filled"
        sx={textFieldSx}
      />
    </Fragment>
  );
}
