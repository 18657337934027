import { Route, Routes } from "react-router-dom";
import AdminUsers from "./AdminUsers";
import ViewUser from "../../../components/ViewUser";

const AdminUsersRouter = () => (
  <Routes>
    <Route path="/" element={<AdminUsers />} />
    <Route path="/:user_id" element={<ViewUser isAdmin={true} />} />
  </Routes>
);

export default AdminUsersRouter;
